<template>
      <div class="col-md-8">
        <div class="card card-chart">
          <div class="card-header">
            <h5 class="card-category">
              Создание технической спецификации на подачу
            </h5>
          </div>
          <div class="card-body">
            <div v-if="inputError" class="alert alert-danger alert-dismissible fade show" role="alert">
                {{inputErrorText}}
                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
              <div class="row align-items-center justify-content-center">
                <div class="col-md-9">
                  <div class="form-group">
                    <input type="text" class="form-control"  v-on:keyup.enter="searchProduct" v-model="searchQuery" placeholder="Наименование оборудования" data-toggle="tooltip" data-placement="top" required/>
                  </div>
                </div>

                <div class="col-md-3">
                  <div class="form-group">
                    <button class="btn btn-info" type="button"  v-on:click.capture="searchProduct">
                      <i class="now-ui-icons ui-1_zoom-bold"></i>
                      Искать
                    </button>
                  </div>
                </div>
              </div>

            <div v-if="products.length > 0 && searchInProcess == false" class="row text-left mt-3 pl-2">
                <div class="col-md-5 font-weight-bold">Наименование</div>
                <div class="col-md-2 font-weight-bold text-center">К-во</div>
                <!--<div class="col-md-3 font-weight-bold">Цена</div>-->
                <div class="col-md-2 font-weight-bold">Добавить</div>
                <div class="col-md-2"></div>
            </div>



            <div class="row text-left mt-3 pl-2" style="display: flex; align-items: center;" v-for="(product,index) in products" :key="product.product_id">

                <div class="col-md-5 text-left" v-if="searchInProcess == false">{{product.product_name}}</div>

                <div class="col-md-2" v-if="searchInProcess == false">
                    <input type="text" class="form-control" v-model="qty" @keypress="isNumber($event)"/>
                </div>

                <!--<div class="col-md-3">
                    <input type="text" class="form-control" name="retail_price" value="100000"/>
                </div>-->

                <div class="col-md-2" v-if="searchInProcess == false">
                    <form method="post" enctype="multipart/form-data">
                        <input type="hidden" class="form-control" name="retail_price" :value="product.retail_price">
                        <input type="hidden" name="product_id" :value="product.product_id">
                        <input type="hidden" name="product_qty" :value="qty">
                        <input type="hidden" name="_token" :value="token">
                        <button type="button" class="btn btn-info add-to-cart" @click="addProduct(index)">
                            <i class="now-ui-icons ui-1_simple-add"></i>
                        </button>
                    </form>
                </div>

                <div class="col-md-2" v-if="searchInProcess == false">
                    <button
                      type="button"
                      class="btn btn-outline-info"
                      data-toggle="modal"
                      :data-target="'#exampleModal'+ product.product_id">
                      <i class="fas fa-eye"></i>
                    </button>
                </div>

                <!-- Modal -->
                <div
                  class="modal fade text-left"
                  :id="'exampleModal'+ product.product_id"
                  tabindex="-1"
                  role="dialog"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true">
                  <div class="modal-dialog modal-lg" role="document">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">
                          Техническая спецификация на <b> {{product.product_name}}</b>
                        </h5>
                        <button
                          type="button"
                          class="close"
                          data-dismiss="modal"
                          aria-label="Close">
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div class="modal-body">
                        <p class="font-weight-bold">{{product.product_name_ts}}</p>
                        <p class="font-weight-bold">Модель: {{product.product_name}}</p>
                        <p class="font-weight-bold">Производитель: {{product.manufacter_name}}</p>
                        <p class="font-weight-bold">Страна производитель: {{product.country_name}}</p>
                        <p>{{product.ts_full_rus}}</p>
                      </div>
                      <div class="modal-footer">
                        <button
                          type="button"
                          class="btn btn-secondary"
                          data-dismiss="modal">
                          Закрыть
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
            </div>


            <div class="row" v-if="products.length == 0 && searchStarted == true && searchInProcess == false">
                  <div class="col-md-6 offset-3 text-center">
                    <img :src="asset('img/empty.gif')" class="rounded" />
                    <p class="text-center">Ничего не найдено</p>
                  </div>
            </div>

             <div class="row mt-5" v-if="searchInProcess">
                  <div class="col-md-6 offset-3 text-center">
                    <div class="spinner-border text-info" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <p class="text-center text-info font-weight-bold mt-2">Ищем товары для Вас...</p>
                  </div>
            </div>
        

            <div class="row" v-if="searchStarted == false && searchInProcess == false">
              <div class="col-md-6 offset-3 text-center">
                <img :src="asset('img/search-icon.png')" class="rounded" />
                <p class="text-center">Начните поиск оборудования</p>
              </div>
            </div>
          </div>
          
          <div class="card-footer">
            <div class="stats text-center">
              <div class="row justify-content-md-center">
                <div class="col-md-4"></div>
              </div>
            </div>
          </div>
        </div>
      </div>

</template>

<script>
export default {
    data: function(){
        return {
            products:[],
            searchQuery: '',
            searchInProcess: false,
            searchStarted: false,
            inputError: false,
            inputErrorText: '',
            qty: 1,
            success: false,
            token: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
            url: "https://expertit.kz"
        }
    },
    mounted() {
     
    },
    methods:{
        searchProduct: function() {
            if(this.searchQuery == ""){
                this.inputError = true;
                this.inputErrorText = "Пожалуйста введите Ваш запрос"
            }
            else{
                this.inputError = false;
                this.searchInProcess = true;
                axios.get(this.url+'/search_ts_full?query='+this.searchQuery).then((response) => {
                    this.searchInProcess = false;
                    this.searchStarted = true;
                   this.products = response.data;
                });
            }
            
        },
        addProduct: function(index){
            if(this.qty == "" || this.qty == 0){
                this.inputError = true;
                this.inputErrorText = "Укажите количество товара"
            }
            else{
                this.inputError = false;
                let currentObj = this;
                let formData = new FormData();
                formData.append('product_id', this.products[index].product_id);
                formData.append('product_qty', this.qty);
                formData.append('retail_price', this.products[index].retail_price);
                axios.post(this.url+'/cart', formData, {
                headers: { 
                    'X-CSRF-TOKEN': this.token
                }
                }).then((response) => { 
                  location.reload();
                });
            }
        },
        isNumber: function(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57))) {
                evt.preventDefault();;
            } 
            else {
                return true;
            }
        }
    }
};
</script>

